<!--
 * @Descripttion : 报表信息
 * @version      : 
 * @Author       : min
 * @Date         : 2020-12-23 19:47:50
 * @LastEditors  : min
 * @LastEditTime : 2020-12-23 21:16:24
-->
<template>
  <div class="ml_report">
    <a-row class="ml_report_num">
      <template v-for="(item, index) in reportList">
        <a-col :span="6" :key="index">
          <a-statistic :title=" item.operationType | typeNameFilter " :value="item.count" />
        </a-col>
      </template>
      <a-col :span="6">
        <a-statistic title="注册用户数" :value="userCountNumber" />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { summaryUserHandle, userCount } from "../utils/api";

export default {
  name: "reportManage",
  data() {
    return {
      reportList: [], //  统计信息
      userCountNumber: 0
    };
  },
  filters: {
    typeNameFilter(type) {
      let typeName = "";
      switch (type) {
        case 1:
          typeName = "车辆拨打电话";
          break;
        case 2:
          typeName = "求购拨打电话";
          break;
        case 3:
          typeName = "店铺拨打电话";
          break;
        case 4:
          typeName = "报价详情拨打电话";
          break;
        case 5:
          typeName = "报价列表拨打电话";
          break;
        case 6:
          typeName = "查看直播详情";
          break;
        case 7:
          typeName = "用户搜索";
          break;
      }
      return typeName;
    }
  },
  created() {
    this.getSummaryUser();
    this.userCount();
  },
  methods: {
    getSummaryUser() {
      const that = this;
      summaryUserHandle().then(res => {
        that.reportList = res.data;
      });
    },
    userCount() {
      const that = this;
      userCount().then(res => {
        that.userCountNumber = res.data;
      });
    },
    onFinish() {
      console.log("finished");
    }
  }
}
</script>
<style lang="scss" scoped>
.ml_report {
  padding: 10px;
  .ml_report_num {
    text-align: center;
  }
}
</style>
